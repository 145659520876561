import { Controller } from '@hotwired/stimulus';
import mapMarkerCenter from '@images/map-marker-center.png';
import getCSSVariable from '../utils/get_css_variable';

const annotationSizes = {
  default: { width: 24, height: 24 },
  selected: { width: 32, height: 32 },
};

export default class extends Controller {
  static targets = ['map', 'listing', 'location'];

  static values = {
    markerColorVar: { type: String, default: 'ck-map-marker--bgcolor' },
    selectedMarkerColorVar: { type: String, default: 'ck-map-marker--bgcolor--selected' },
  };

  initialize() {
    this.handleListingEnter = this.handleListingEnter.bind(this);
    this.handleListingLeave = this.handleListingLeave.bind(this);
    this.annotations = new Set();
    this.listingsMap = new WeakMap();
    this.locationsMap = new WeakMap();
    this.previousY = window.scrollY;
    this.activeListing = null;
    this.defaultVisibleCoordinateRegion = null;
    this.extrema = {
      north: null, south: null, east: null, west: null,
    };
  }

  handleListingEnter(event) {
    this.updateActiveListing(event.target);
  }

  handleListingLeave(event) {
    if (this.activeListing !== event.target) return;
    if (this.listingTargets.indexOf(event.target) === 0 && event.detail.scrollDirection === 'up') {
      this.clearActiveListing();
    }
  }

  get markerColor() {
    return getCSSVariable(this.element, this.markerColorVarValue);
  }

  get selectedMarkerColor() {
    return getCSSVariable(this.element, this.selectedMarkerColorVarValue);
  }

  /**
   * Delta between northernmost and southernmost locations.
   *
   * @returns {number}
   */
  get longitudeDelta() {
    return Math.abs(this.extrema.east - this.extrema.west) * this.minimumSpanMultiplier;
  }

  /**
   * Delta between easternmost and westernmost locations.
   *
   * @returns {number}
   */
  get latitudeDelta() {
    return Math.abs(this.extrema.north - this.extrema.south) * this.minimumSpanMultiplier;
  }

  /**
   * Multiplier for the coordinate span based on the viewport size.
   *
   * @returns {number}
   */
  get minimumSpanMultiplier() {
    return this.isMobile ? 0.75 : 1;
  }

  /**
   * Default minimum span to use when zooming the map. Calculated based on the
   * extrema of the locations to avoid sweeping pans or zooms.
   *
   * @returns {window.mapkit.CoordinateSpan}
   */
  get minimumSpan() {
    return new window.mapkit.CoordinateSpan(this.latitudeDelta, this.longitudeDelta);
  }

  async connect() {
    this.isConnected = false;

    this.observeMapHeight();
    this.observeViewport();
    this.observeListingIntersections();

    await window.mapkitInitialized;

    this.element.querySelectorAll('[data-map-target="listing"]').forEach((listingEl) => {
      const title = listingEl.dataset.name;
      const locationEls = Array.from(listingEl.querySelectorAll('[data-location-id]'));
      const annotations = [];
      locationEls.forEach((locationEl) => {
        const annotation = this.addAnnotation(locationEl, { title });
        if (annotation) annotations.push(annotation);
      });

      this.listingsMap.set(listingEl, annotations);
      listingEl.addEventListener('enter', this.handleListingEnter);
      listingEl.addEventListener('leave', this.handleListingLeave);
      this.listingObserver.observe(listingEl);
    });

    await this.initializeMap();

    this.map.addAnnotations(Array.from(this.annotations));
    this.map.showItems(Array.from(this.annotations));

    this.defaultVisibleCoordinateRegion = this.map.visibleMapRect.toCoordinateRegion();
    this.map.cameraBoundary = this.defaultVisibleCoordinateRegion;
    this.isConnected = true;
  }

  get zoomOnSelect() {
    return !!this.isMobile;
  }

  get mapPadding() {
    if (this.isMobile) {
      return new window.mapkit.Padding({
        top: 50, right: 50, bottom: 50, left: 50,
      });
    }
    return new window.mapkit.Padding({
      top: 100, right: 100, bottom: 100, left: 100,
    });
  }

  handleViewportChange(e) {
    this.isMobile = e.matches;
  }

  observeMapHeight() {
    this.mapHeight = this.mapTarget.offsetHeight;
    this.imageResizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.contentBoxSize) {
          this.mapHeight = entry.contentBoxSize[0].blockSize;
          this.element.style.setProperty('--map-height', `${this.mapHeight}px`);
        }
      });
    });
    this.imageResizeObserver.observe(this.mapTarget);
  }

  observeViewport() {
    this.mq = window.matchMedia('(max-width: 45rem)');
    this.mq.addEventListener('change', this.handleViewportChange.bind(this));
    this.isMobile = this.mq.matches;
  }

  observeListingIntersections() {
    this.listingObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const currentY = window.scrollY;
        const { isIntersecting } = entry;

        const scrollDirection = currentY < this.previousY ? 'up' : 'down';

        if (isIntersecting) {
          entry.target.dispatchEvent(new CustomEvent('enter', { bubbles: true }));
        } else {
          entry.target.dispatchEvent(new CustomEvent('leave', { bubbles: true, detail: { scrollDirection } }));
        }

        this.previousY = currentY;

        // const eventName = entry.isIntersecting ? 'enter' : 'leave';
        // entry.target.dispatchEvent(new CustomEvent(eventName, { bubbles: true }));
      });
    }, {
      rootMargin: '-50% 0px -50% 0px',
    });
  }

  async initializeMap() {
    await window.mapkitInitialized;

    if (!this.map) {
      this.map = new window.mapkit.Map(this.mapTarget, {
        mapType: 'mutedStandard',
        pointOfInterestFilter: window.mapkit.PointOfInterestFilter.including([
          window.mapkit.PointOfInterestCategory.ATM,
          window.mapkit.PointOfInterestCategory.Park,
          window.mapkit.PointOfInterestCategory.PublicTransport,
          window.mapkit.PointOfInterestCategory.Parking,
          window.mapkit.PointOfInterestCategory.School,
        ]),
        showsMapTypeControl: false,
        showsZoomControl: false,
        showsScale: false,
        padding: this.mapPadding,
      });
    }
  }

  listingTargetDisconnected(element) {
    this.listingMaps.delete(element);
    this.annotations.delete(element);
    element.removeEventListener('enter', this.handleListingEnter);
  }

  addAnnotation(element, options = {}) {
    const { lat, lng, locationId } = element.dataset;
    const latitude = parseFloat(lat);
    const longitude = parseFloat(lng);

    let annotation = null;

    if (!Number.isNaN(latitude) && !Number.isNaN(longitude)) {
      this.updateExtrema(latitude, longitude);
      const coords = new window.mapkit.Coordinate(latitude, longitude);
      annotation = new window.mapkit.MarkerAnnotation(coords, {
        // Don't animate the annotation when it is added to the map
        animates: false,
        // clusteringIdentifier: 'map',
        color: this.markerColor || null,
        // title: options.title,
        size: annotationSizes.default,
        enabled: false,
        glyphImage: {
          3: mapMarkerCenter,
        },
        data: {
          elementId: element.id,
          title: options.title,
        },
        // callout: {
        //   calloutElementForAnnotation: (annotation) => {
        //     const calloutElement = document.createElement('div');
        //     calloutElement.classList.add('zanzibar');
        //     const title = calloutElement.appendChild(document.createElement('h2'));
        //     title.textContent = annotation.title;

        //     calloutElement.style.width = '200px';
        //     calloutElement.style.height = '200px';
        //     return calloutElement;
        //   },
        // },
      });
      this.annotations.add(annotation);
      this.locationsMap.set(element, annotation);
    }

    return annotation;
  }

  updateVisibleMapRegion() {
    if (this.activeAnnotations === null || this.activeAnnotations.length === 0) {
      this.map.showItems(Array.from(this.annotations), { animate: true });
    } else {
      const { latitudeDelta, longitudeDelta } = this.defaultVisibleCoordinateRegion.span;
      const zoomSpan = new window.mapkit.CoordinateSpan(latitudeDelta * 0.8, longitudeDelta * 0.8);

      this.map.showItems(Array.from(this.activeAnnotations), {
        animate: true,
        minimumSpan: zoomSpan,
      });
    }
  }

  updateExtrema(latitude, longitude) {
    if (this.extrema.north === null || latitude > this.extrema.north) {
      this.extrema.north = latitude;
    }
    if (this.extrema.south === null || latitude < this.extrema.south) {
      this.extrema.south = latitude;
    }
    if (this.extrema.east === null || longitude > this.extrema.east) {
      this.extrema.east = longitude;
    }
    if (this.extrema.west === null || longitude < this.extrema.west) {
      this.extrema.west = longitude;
    }
  }

  highlightAnnotations(annotations) {
    annotations.forEach((annotation, index) => {
      if (!annotation) return;
      this.map.removeAnnotation(annotation);

      if (annotations.length > 1) {
        annotation.glyphText = (index + 1).toString();
      }
      annotation.color = this.selectedMarkerColor;
      annotation.size = annotationSizes.selected;
      annotation.title = annotation.data.title;

      this.map.addAnnotation(annotation);
    });
  }

  unhighlightAnnotations(annotations) {
    annotations.forEach((annotation) => {
      if (!annotation) return;

      this.map.removeAnnotation(annotation);
      annotation.glyphText = '';
      annotation.color = this.markerColor;
      annotation.selected = false;
      annotation.title = null;
      this.map.addAnnotation(annotation);
    });
  }

  updateActiveListing(element) {
    if (this.activeListing === element) return;
    if (this.activeListing) {
      this.activeListing.classList.remove('test-active');
      this.unhighlightAnnotations(this.listingsMap.get(this.activeListing));
    }
    element.classList.add('test-active');
    this.highlightAnnotations(this.listingsMap.get(element));
    this.activeListing = element;
    this.updateVisibleMapRegion();
  }

  clearActiveListing() {
    if (!this.activeListing) return;
    this.activeListing.classList.remove('test-active');
    this.unhighlightAnnotations(this.listingsMap.get(this.activeListing));
    this.activeListing = null;
    this.updateVisibleMapRegion();
  }

  get activeAnnotations() {
    return this.activeListing ? this.listingsMap.get(this.activeListing) : null;
  }
}
